import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { keyframes } from '@mui/system';
import { statusToIcon, statusToSpanish } from '../../helpers';
import { useIsMobile } from '../../hooks';

const StatusTimeline = ({ statusSteps }) => {
  const isMobile = useIsMobile();

  const twinkleAnimation = keyframes`
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
  `;

  return (

    <Timeline
      position={!isMobile && 'alternate'}
      sx={isMobile && {
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        justifyItems: 'space-between',
        height: '80%',
      }}
    >
      {statusSteps.map((step) => (
        <TimelineItem key={step.id}>
          <TimelineSeparator>
            <TimelineDot sx={{
              animation: (step.actualState && !step.finished) ? `${twinkleAnimation} 2.5s infinite` : 'none',
              backgroundColor: (step.finished || step.actualState) && 'primary.main',

            }}
            >
              {statusToIcon[step.status]}
            </TimelineDot>
            {step.status !== 'FINISHED' && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6">
              {statusToSpanish(step.finished)[step.status]}
            </Typography>
            <Typography variant="body2">
              {step.startedDatetime}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
              {step.extraComponent}
            </Box>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

StatusTimeline.propTypes = {
  statusSteps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      datetime: PropTypes.instanceOf(Date),
    }),
  ).isRequired,
};

export default StatusTimeline;
