import React from 'react';
import {
  Tooltip,
  styled,
  tooltipClasses,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
  },
}));

const HtmlTooltip = styled(({ className, transparentBg, ...params }) => {
  const tooltipClass = useStyles();
  const classes = transparentBg ? { ...tooltipClass, popper: className } : { popper: className };
  return (
    <Tooltip {...params} classes={classes} />
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
  },
}));

export default HtmlTooltip;
