/* eslint-disable import/prefer-default-export */
import { red, orange, amber, lime, lightGreen } from '@mui/material/colors';

const collectionPriorityColors = {
  1: red[500],
  2: orange[500],
  3: amber[400],
  4: lime[500],
  5: lightGreen[500],
};

export default collectionPriorityColors;
