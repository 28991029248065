import React from 'react';
import PropTypes from 'prop-types';
import EmailVerificationTooltip from './EmailVerificationTooltip';
import PhoneVerificationTooltip from './PhoneVerificationTooltip';

const VerificationTooltip = ({ verificationAttribute }) => {
  if (verificationAttribute === 'isEmailVerified') return <EmailVerificationTooltip />;
  return (
    <PhoneVerificationTooltip />
  );
};

VerificationTooltip.propTypes = {
  verificationAttribute: PropTypes.string.isRequired,
};

export default VerificationTooltip;
