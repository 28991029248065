import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const TooltipTypography = ({ children, title, ...props }) => (
  <Tooltip title={title || children}>
    <Typography
      {...props}
    >
      {children}
    </Typography>
  </Tooltip>
);

TooltipTypography.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};

TooltipTypography.defaultProps = {
  title: null,
  children: '',
};

export default TooltipTypography;
