import { formatMoney } from '../helpers';

const companyCessionsTooltipHandler = (clientHistory) => {
  if (clientHistory === null) return null;
  return (`
    Información histórica con Fingo:

    N° de Facturas cliente: ${clientHistory?.cessionsNumber || 0}
    Monto facturas cliente: $${formatMoney(clientHistory?.cessionsMoneyAmount || 0)}
    N° de Facturas historicas cliente: ${clientHistory?.historicCessionsNumber || 0}
    Monto facturas historicas cliente: $${formatMoney(clientHistory?.historicCessionsMoneyAmount || 0)}

`);
};

export const companyReceiverCessionsTooltip = (clientReceiverHistory, receiverHistory) => {
  if (clientReceiverHistory === receiverHistory === {}) return null;
  return (`
    N° de Facturas cliente-deudor: ${clientReceiverHistory?.cessionsNumber || 0}
    Monto facturas cliente-deudor: $${formatMoney(clientReceiverHistory?.cessionsMoneyAmount || 0)}
    N° de Facturas historicas cliente-deudor: ${clientReceiverHistory?.historicCessionsNumber || 0}
    Monto facturas historicas cliente-deudor: $${formatMoney(clientReceiverHistory?.historicCessionsMoneyAmount || 0)}

    N° de Facturas Deudor: ${receiverHistory?.cessionsNumber || 0}
    Monto facturas Deudor: $${formatMoney(receiverHistory?.cessionsMoneyAmount || 0)}
    N° de Facturas historicas Deudor: ${receiverHistory?.historicCessionsNumber || 0}
    Monto facturas historicas Deudor: $${formatMoney(receiverHistory?.historicCessionsMoneyAmount || 0)}
`);
};

const invoiceCessionsTooltipHandler = (clientHistory, clientReceiverHistory, receiverHistory) => {
  const companyCessions = companyCessionsTooltipHandler(clientHistory);
  const companyReceiverCessions = companyReceiverCessionsTooltip(
    clientReceiverHistory,
    receiverHistory,
  );
  if (companyCessions === companyReceiverCessions === null) return null;
  return ((companyCessions || '').concat(companyReceiverCessions || ''));
};

export { companyCessionsTooltipHandler, invoiceCessionsTooltipHandler };
