/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const LoadingTypography = ({ loading, children, ...typographyProps }) =>
  loading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color="inherit" size="20px" />
    </Box>
  ) : (
    <Typography {...typographyProps}>{children}</Typography>
  );

LoadingTypography.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LoadingTypography.defaultProps = {
  loading: false,
};

export default LoadingTypography;
