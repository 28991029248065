import React, { useEffect, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import PropTypes from 'prop-types';
import i18n from '../../helpers/i18n';
import getCountryFromTld from '../../helpers/country-from-tld';
import { useSelectedCompany } from '../../hooks';

const TranslationProvider = ({ children }) => {
  const selectedCompany = useSelectedCompany();
  const country = useMemo(() => getCountryFromTld(), [selectedCompany]);

  useEffect(() => {
    if (country) {
      i18n.changeLanguage(country);
    }
  }, [country]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

TranslationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TranslationProvider;
