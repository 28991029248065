import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const Bold = ({ children, fontWeight }) => (
  <Box display="inline" fontWeight={fontWeight}>{ children }</Box>
);

Bold.propTypes = {
  children: PropTypes.node.isRequired,
  fontWeight: PropTypes.number,
};

Bold.defaultProps = {
  fontWeight: 700,
};

export default Bold;
