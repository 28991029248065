// ButtonTrackerApp.js
import { useCallback, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import useAnalytics from './google/useAnalytics';

const ButtonTrackerApp = ({ children: originalChildren }) => {
  const { trackEvent } = useTracking();
  const { trackEvent: trackGoogleEvent } = useAnalytics();

  const handleButtonClick = useCallback((pointerEvent) => {
    let { target } = pointerEvent;
    let parentCounter = 0;
    while (target !== null && parentCounter < 4) {
      if (target.nodeName === 'BUTTON' && target.id) {
        const eventDetails = {
          action: 'CLICK',
          button: target.id,
        };
        trackEvent(eventDetails);
        trackGoogleEvent('Button', 'Click', target.id);
        break;
      }
      target = target.parentNode;
      parentCounter += 1;
    }
  }, [trackEvent, trackGoogleEvent]);

  useEffect(() => {
    window.addEventListener('click', handleButtonClick, false);
    return () => window.removeEventListener('click', handleButtonClick, false);
  }, [handleButtonClick]);
  return originalChildren;
};

export default ButtonTrackerApp;
