import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const StepTypography = ({ stepNumber, children, ...props }) => (
  <Typography {...props}>
    <Box color="primary.main" fontWeight="bold" component="span">
      {stepNumber}.
    </Box>{' '}
    {children}
  </Typography>
);

StepTypography.propTypes = {
  stepNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  children: PropTypes.node.isRequired,
};

export default StepTypography;
