import React from 'react';
import PropTypes from 'prop-types';
import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const InformationTooltip = ({ size, ...props }) => (
  <Tooltip {...props}>
    <Info fontSize={size} />
  </Tooltip>
);

InformationTooltip.propTypes = {
  size: PropTypes.string,
};

InformationTooltip.defaultProps = {
  size: 'medium',
};

export default InformationTooltip;
